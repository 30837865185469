<template>
  <b-card class="admin-knowledge-base-category">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="categoryRef"
          :button-label="$t('Add Knowledge Base Category')"
          :columns="knowledgeColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/admin/knowledge/categories'"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showItemsModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Category' : 'Add Category'"
      size="lg"
      ok-only
      :ok-title="$t('Save Changes')"
      @hidden="showItemsModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Category Name')">
            <b-form-input
              v-model="dataInfo.title"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Description')">
            <b-form-input
              v-model="dataInfo.description"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.status"
              :options="[
                {
                  text: 'Active',
                  value: '1',
                },
                {
                  text: 'Inactive',
                  value: '0',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="dataInfo.picture"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from '../../users/shared/ImageInput.vue'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    GoodDataTable,
    ImageInput,
    BFormGroup,
    BFormSelect,
    BFormInput,
  },
  data() {
    return {
      showItemsModal: false,
      dataInfo: {
        title: '',
        description: '',
        status: '',
        picture: '',
      },
      knowledgeColumns: [
        {
          label: 'Category',
          field: 'title',
        },
        {
          label: 'Category Description',
          field: 'description',
        },
        {
          label: 'Status',
          field: 'status',
          rField: 'status_text',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            // eslint-disable-next-line eqeqeq
            if (props.status == 1) {
              return 'Active'
            }
            return 'Inactive'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.dataInfo = window.SuperApp.actions.cloneDeep(props)
                this.showItemsModal = true
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this category?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deleteKnowledge(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.showItemsModal = true
    },
    editKnowledge() {
      const { updateKnowledgeCategories } = useAdminUi()
      const formData = new FormData()
      delete this.dataInfo.status_text
      delete this.dataInfo.originalIndex
      delete this.dataInfo.vgt_id
      Object.keys(this.dataInfo).forEach(x => {
        formData.append(x, this.dataInfo[x])
      })
      formData.append('_method', 'PATCH')
      showLoader()
      updateKnowledgeCategories({
        data: formData,
        id: this.dataInfo.id,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.categoryRef.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    deleteKnowledge(props) {
      const { deleteKnowledgeCategoriesById } = useAdminUi()
      showLoader()
      deleteKnowledgeCategoriesById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.categoryRef.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    createKnowledge() {
      const { addKnowledgeCategories } = useAdminUi()
      const formData = new FormData()
      Object.keys(this.dataInfo).forEach(x => {
        formData.append(x, this.dataInfo[x])
      })
      showLoader()
      addKnowledgeCategories(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.categoryRef.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFile(payload) {
      if (this.dataInfo) this.dataInfo.picture = payload
    },
    saveData() {
      if (this.dataInfo.id) {
        this.editKnowledge()
      } else {
        this.createKnowledge()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
